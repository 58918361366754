.Last-Step {
	position: relative;
	min-height: 100vh;
}


.Last-Step input[type=text] {
	border-radius: 0;
	-webkit-appearance: none;
}

.Last-Step .whole-container {
	display: flex;
	justify-content: center;
	padding: 127px 0;
}

.Last-Step .whole-container .wrapper {
	max-width: 1000px;
	width: max-content;
}

.Last-Step .whole-container h1 {
	margin-bottom: 75px;
	font-size: 25px;
	font-weight: 800;
}

.Last-Step .whole-container h2 {
	margin-bottom: 5px;
	font-size: 16px;
	font-weight: 700;
}

.Last-Step .paypalme {
	font-size: 14px;
	font-weight: 500;
}

.Last-Step .paypalme a {
	color: #3FA1EC;
}





/* FORM */
  
  .Last-Step .wrapper .form {
	display: flex;
	max-width: 1000px;
	width: max-content;
	flex-direction: column;
  }

  .Last-Step .textField-paypal {
	margin-top: 8px;
	border: none;
	text-align: left;
	font-family: 'Montserrat', sans-serif;
	outline: none;
	background-color: #F2F2F2;
	width: 315px;
	height: 45px;
	letter-spacing: 1px;
	font-size: 13px;
	color: #282828;
  	padding: 0 14px;
}	

.Last-Step .form h3 {
	font-size: 9px;
	color: #2D2D2D;
	letter-spacing: 0.7px;
	text-align: left;
	font-weight: 500;
}

.Last-Step .input-header-insta {
	margin-top: 32px;
}

.Last-Step .emptyMessage {
	margin-top: 9px;
	font-weight: 600;
	font-size: 9px;
	color: #C10A0A;
	letter-spacing: 1.29px;
	text-align: left;
}

/*  */

.Last-Step .form p {
	font-weight: 600;
	font-size: 10px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.Last-Step .checkbox-header-1 {
	margin-top: 77px;
}
.Last-Step .checkbox-header-2 {
	margin-top: 34px;
}

.Last-Step .checkbox-conatiner-1 {
	margin-top: 8px;
	display: grid;
	grid-template-columns: 15px auto;
	grid-template-rows: auto;
	/* display: flex;
	flex-direction: row;
	justify-content: center; */
}

.Last-Step .form label {
	grid-column: 2/3;
	margin-left: 8px;
	letter-spacing: 0.3px;
	font-size: 0.8rem;
}

.Last-Step .checkbox + label {
	cursor:pointer;
}

.Last-Step .checkbox {
	grid-column: 1/2;
	min-width: 15px;
	min-height: 15px;
	width: 15px;
	height: 15px;
}

.Last-Step .checkbox-conatiner-2 {
	margin-top: 7px;
}





.Last-Step .sendBut {
	width: 141px;
	height: 37px;
	background-color: #282828;
	color: white;
	font-size: 13px;
	letter-spacing: 2px;
	font-weight: 600;
	margin-top: 80px;
  }



  .Last-Step .contact {
	margin-top: 62px;
	font-weight: 600;
	font-size: 10px;
	color: #282828;
	letter-spacing: 0.1px;
	text-align: left;
  }

  .Last-Step .contact a {
	color: #3FA1EC;
  }

  .Last-Step .status {
	margin-top: 26px;
	font-size: 10px;
	color: #DB0404;
	font-weight: 700;
	letter-spacing: 0.8px;
	text-transform: uppercase;
}




@media only screen and (max-width: 1700px) {	
	.Last-Step .whole-container .wrapper {
		padding-left: 200px;
		width: 900px;
	}

	.Last-Step .wrapper .form {
		width: 900px;
	  }
}

@media only screen and (max-width: 1200px) {	
	.Last-Step .whole-container .wrapper {
		padding-left: 0px;
		width: calc(100vw - 80px);
	}

	.Last-Step .wrapper .form {
		width: calc(100vw - 80px);
	}

	.Last-Step .whole-container {
		padding: 127px 0;
	}
}

  /* Phone */
@media only screen and (max-width: 750px) {	
	.Last-Step .whole-container {
		display: flex;
		justify-content: center;
		padding: 90px 0 250px 0;
	}

	.Last-Step .whole-container .wrapper {
		width: calc(100vw - 40px);
	}
	.Last-Step .wrapper .form {
		width: calc(100vw - 40px);
	}

	.Last-Step .status {
		margin-left: 20px; margin-right: 20px;
		text-align: center;
	}

	/* SUBMIT */
	.Last-Step .sendBut {
		max-width: 480px;
		width: calc(100vw - 40px);
		height: 47px;
		font-size: 14px;
		letter-spacing: 0.8px;
		font-weight: 700;
		margin-top: 50px;
	}
}