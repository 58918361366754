* {
	margin: 0;
	padding: 0;
  box-sizing: border-box;
  background-color: white;
}

html {
	font-family: 'Montserrat', sans-serif;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap');