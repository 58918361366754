.ThankYou {
	position: relative;
	min-height: 100vh;
}

.ThankYou .wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
}

.ThankYou .content h1 {
	font-weight: 700;
	letter-spacing: 1.33px;
	text-align: center;
}
.ThankYou .submit-h1 {
	font-size: 28px;
}

.ThankYou .last-h1 {
	font-size: 38px;
}

.ThankYou .content h2 {
	font-weight: 700;
	letter-spacing: 1.33px;
	font-size: 24px;
	text-align: center;
}

.ThankYou .h p {
	font-weight: 600;
	font-size: 13px;
	letter-spacing: 0.78px;
	text-align: center;
	margin-top: 12px;
}

.ThankYou .content .more {
	background: #282828;
	font-weight: 700;
	font-size: 11px;
	color: #FFFFFF;
	letter-spacing: 0.78px;
	text-align: center;
	width: 217px;
	height: 42px;
	margin-top: 51px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ThankYou .h {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 0 20px;
}


.ThankYou .content .contact {
	position: absolute;
	bottom: 13.39vh;
	font-weight: 600;
	font-size: 11px;
	letter-spacing: 0.78px;
	text-align: center;
}

.ThankYou .content .contact a {
	color: #3FA1EC;
}




@media only screen and (max-width: 1200px) {
	.ThankYou .content .contact {
		bottom: 20.5vh;
	}
	
	.ThankYou .content .contact a {
		color: #3FA1EC;
	}
}


@media only screen and (max-width: 750px) {

	.ThankYou .wrapper {
		display: inline;
	}

	.ThankYou .content .contact {
		position: static;
		margin-top: 110px;
		padding-bottom: 220px;
	}

	.ThankYou .submit-h1 {
		margin-top: 117px;
	}

	.ThankYou .last-h1 {
		font-size: 30px;
		line-height: 35px;
		margin-top: 117px;
	}
	
	.ThankYou .h h2 {
		font-size: 19px;
		line-height: 25px;
		margin-top: 3px;
	}

	.ThankYou .h p {
		font-size: 12px;
	}
}