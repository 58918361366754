@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap);
* {
	margin: 0;
	padding: 0;
  box-sizing: border-box;
  background-color: white;
}

html {
	font-family: 'Montserrat', sans-serif;
}
.Header {
	background-color: rgb(0, 0, 0, 0);
	position: fixed;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: 160px;
	margin: 45px 0 0 45px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	z-index: 10;
}

.Header div {background-color: rgb(0, 0, 0, 0);}

.Header .brand {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	font-size: 21px;
	font-weight: 800;
	color: #282828;
	letter-spacing: 3px;
	background-color: rgba(0, 0, 0, 0);
}

.Header .choose-container {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	height: 54px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.Header .choose-container .gender {
	font-weight: 400;
	font-size: 15px;
	letter-spacing: 5px;
	color: #282828;
}

.headerFooter {z-index: 10;}


.headerFooter div {background-color: rgb(0, 0, 0, 0)}

.Terms {
	position: fixed;
	left: 0;
	bottom: -11px;
	margin: 45px;
	display: flex;
	justify-content: space-between;
	-webkit-transform-origin: top left;
	        transform-origin: top left; /* IE 9 */
	-webkit-transform: rotate(-90deg); /* Safari 3-8 */
	transform: rotate(-90deg);
	flex-wrap: wrap;
	z-index: 10;
}

.Terms .info {
	margin-right: 40px;
	font-size: 9px;
	font-weight: 600;
	letter-spacing: 8.4px;
	color: #282828;
}



@media only screen and (max-height: 950px) {
	.Terms .info {
		letter-spacing: 5.5px;
	}
}

@media only screen and (max-height: 800px) {
	.Terms .info {
		margin-right: 20px;
		letter-spacing: 3px;
	}
}

@media only screen and (max-height: 635px) {
	.Terms .info {
		letter-spacing: 0px;
	}
}





/* SOZIAL */
.sozial-conatiner {
	position: fixed;
	bottom: 45px;
	left: 101px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	z-index: 10;
}


.sozialIcon-container {
	display: grid;
	grid-template-rows: 13px 16px 13px;
}

.sozialIcon-container .facebookBut {
	width: 20px;
	height: 20px;
	background-color: rgba(0, 0, 0, 0);
}

.sozialIcon-container .facebookIcon {
	width: auto;
	height: 15px;
}

.sozialIcon-container .instagramBut {
	width: 20px;
	height: 20px;
	grid-row: 3/4;
	background-color: rgba(0, 0, 0, 0);
}

.sozialIcon-container .instagramIcon {
	width: auto;
	height: 15px;
}



.appStore {
	display: flex;
	align-items: flex-end;
	margin-left: 45px;
}

.appStore .apple {
	width: 13px;
	height: 13px;
	background-color: rgba(0, 0, 0, 0);
}

.appStore p {
	font-size: 9px;
	font-weight: 500;
	letter-spacing: 2px;
	margin-left: 11px;
}
.biggerNav-container {
	position: relative;
	z-index: 10;
	width: 100%;
	position: fixed;
	display: flex;
	align-items: center;
	transition: .3s;
	-webkit-transition: .3s;
	backdrop-filter: blur(12px);
	-webkit-backdrop-filter: blur(12px);
}



@-webkit-keyframes colorchange {
	0%   {background-color: white}
	100% {background-color: rgba(255, 255, 255, 0.93)}
}



@keyframes colorchange {
	0%   {background-color: white}
	100% {background-color: rgba(255, 255, 255, 0.93)}
}
  
  @-webkit-keyframes colorchangeBack { 
	/* 0%   {background-color: rgba(238, 238, 238, 0.9)} */
	0%   {background-color: rgba(255, 255, 255, 0.93)}
	100% {background-color: white}
  }
  
  @keyframes colorchangeBack { 
	/* 0%   {background-color: rgba(238, 238, 238, 0.9)} */
	0%   {background-color: rgba(255, 255, 255, 0.93)}
	100% {background-color: white}
  }
  

  @-webkit-keyframes blurChange {
	0% {
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px)
	}

	100% {
		backdrop-filter: blur(12px);
		-webkit-backdrop-filter: blur(9px)
	}
  }
  

  @keyframes blurChange {
	0% {
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px)
	}

	100% {
		backdrop-filter: blur(12px);
		-webkit-backdrop-filter: blur(9px)
	}
  }
	
  @-webkit-keyframes blurChangeBack {
	0% {
		backdrop-filter: blur(12px);
		-webkit-backdrop-filter: blur(9px)
	}
	
	100% {
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px)
	}
  }
	
  @keyframes blurChangeBack {
	0% {
		backdrop-filter: blur(12px);
		-webkit-backdrop-filter: blur(9px)
	}
	
	100% {
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px)
	}
  }


.biggerNav-container nav {
	margin: 0px 07.083333vw;
	height: 24px;
	width: 85.833334vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0);
}

.biggerNav-container nav .brand {
	text-decoration: none;
	font-size: 20px;
	letter-spacing: 3px;
	background-color: rgba(0, 0, 0, 0);
	font-weight: 800
}

.biggerNav-container nav ul {
	height: 100%;
	/* max-width: 347px; */
	/* max-width: 218px; */
	/* width: 25vw; */
	width: 13.625rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	background-color: rgba(0, 0, 0, 0);
}

.biggerNav-container nav ul li {
	background-color: rgba(0, 0, 0, 0);
	height: 100%;
	display: flex;
	align-items: center;

}


.biggerNav-container .chooseGender {
	display: flex;
	align-items: center;
	height: 100%;
	color: #282828;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 2px;
	font-weight: 600;
}





/* *** PHONE *** */
.phoneNav-container {
	display: none;
	position: fixed;
	top: 0; left: 0; right: 0;
	height: 48px;
}

.phoneNav-container nav {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	position: relative;
}

/* Icon */
.phoneNav-container .menuIcon {
	background-color: white;
	position: absolute;
	width: 57px;
	height: 100%;
	left: 0;
}

.phoneNav-container .menuIcon .icon-container {
	background-color: white;
	width: 21px;
	height: 10px;
	margin-left: 18px;
}

.phoneNav-container .menuIcon .icon-container div {
	display: absolute;
	width: 100%;
	height: 2px;
	background-color: #282828;
	-webkit-transition: .4s;
	transition: .4s;
}

.phoneNav-container .menuIcon .topLayer {
	margin-top: 0px;
}

.phoneNav-container .menuIcon .bottomLayer {
	margin-top: 6px;
}

.phoneNav-container .menuIcon .topLayer-open {
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
	margin-top: 5.25px;
}

.phoneNav-container .menuIcon .bottomLayer-open {
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
	margin-top: -2px;
}


/* Brand */
.phoneNav-container .brand {
	font-size: 15px;
	font-weight: 700;
	color: #282828;
	letter-spacing: 5.7px;
}

/* SHARE */
.phoneNav-container .share {
	position: absolute;
	background-color: orange;
	width: 48px;
	height: 100%;
	top: 0px; right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.phoneNav-container .share .button {
	width: 100%;
	height: 100%;
}

.phoneNav-container .share img {
	object-fit: contain;
}




/* Overlay Menu */
.phoneMenuOverlay {
	z-index: 100;
	position: fixed;
	overflow: hidden;
	width: 100%;
	/* height: 122px; */
	top: 48px; 
	-webkit-transition: max-height 0.52s;
	transition: max-height 0.52s;
	-webkit-transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
}

.phoneMenuOverlay .background {
	width: 100%;
	height: 122px;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 36px;	
	position: relative;
	z-index: 100;
}


.phoneMenuOverlay .background .text {
	position: relative;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 5px;
	-webkit-transition: 0.52s;
	transition: 0.52s;
	-webkit-transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	transition-timing-function: cubic-bezier(0.52, 0.16, 0.24, 1);
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.overlayBack {
	position: fixed;
	width: 100%;
	z-index: 99;
	transition: background-color .3s;
	-webkit-transition: background-color .3s;
}

@-webkit-keyframes overlayAlphaChange {
	0%   {background-color: rgba(0, 0, 0, 0)}
	100% {background-color: rgba(0, 0, 0, 0.2)}
}

@keyframes overlayAlphaChange {
	0%   {background-color: rgba(0, 0, 0, 0)}
	100% {background-color: rgba(0, 0, 0, 0.2)}
}

@-webkit-keyframes overlayAlphaChangeBack {
	0%   {background-color: rgba(0, 0, 0, 0.2)}
	100% {background-color: rgba(0, 0, 0, 0)}
}

@keyframes overlayAlphaChangeBack {
	0%   {background-color: rgba(0, 0, 0, 0.2)}
	100% {background-color: rgba(0, 0, 0, 0)}
}





/* Tablet */
@media only screen and (max-width: 1200px) {
	.biggerNav-container nav {
		width: 100vw;
		margin: 0;
		padding: 0px 40px;
	}
}

/* Phone */
@media only screen and (max-width: 750px) {
	.biggerNav-container {
		display: none;
	}

	.phoneNav-container {
		z-index: 101;
		display: inline;
	}
}


.Footer {
	bottom: 0;
	/* margin-bottom: -97px; */
}

@supports (padding-bottom: constant(safe-area-inset-bottom)) {
	.Footer {
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		bottom: calc(var(--safe-area-inset-bottom));
	}
}
@supports (padding-bottom: env(safe-area-inset-bottom)) {
	.Footer {
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		bottom: calc(var(--safe-area-inset-bottom));
	}
} 

.Footer footer {
	/* background-color: rebeccapurple; */
	width: 100vw;
	height: 97px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 07.083333vw;
}

.Footer p {background-color: rgba(0, 0, 0, 0);}


/* Terms */
.Footer .terms {
	position: relative;
	height: 41px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* flex-wrap: wrap; */
	background-color: white;
}

.Footer .terms .footerLinks {
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 3px;
	margin-right: 30px;
}

.Footer .terms :last-child {margin-right: 0px;}

/* Promo */
.Footer .promo {
	display: flex;
	align-items: center;
	background-color: white;
	height: 41px;
}

.Footer .socialList {
	margin-right: 39px;
	background-color: white;
}

.Footer .socialList .icon {
	width: 17px;
	height: 17px;
	background-color: white;
}

.Footer .socialList .instagram {
	margin-left: 15px;
}

.Footer .appStoreBut {
	width: 275px;
	height: 41px;
	background-color: #2C2C2C;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Footer .appStoreBut p {
	color: white;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 3.9px;
}

.Footer .appStoreBut .apple {
	margin-right: 11px;
	width: 11px;
	height: 13px;
	background-color: rgba(0, 0, 0, 0)
}




/* Tablet */
@media only screen and (max-width: 1290px) {

}

@media only screen and (max-width: 1200px) {
	.Footer footer {padding: 0px 40px;}
	.Footer .terms .footerLinks {margin-right: 29px;}
	.Footer .terms :last-child {margin-right: 0px;}

	.Footer .socialList {margin-right: 27px}
	.Footer .socialList .instagram {margin-left: 13px;}
	.Footer .terms .footerLinks {font-size: 9px;}
}

@media only screen and (min-height: 1200px) and (max-width: 1290px) {

}


/* Mobile */
@media only screen and (max-width: 1000px) {
	.Footer .terms {align-items: flex-end;}
	.Footer .promo {align-items: flex-end;}

	.Footer .terms :first-child {
		position: absolute;
		top: 0;
	}

	.Footer .terms .footerLinks {
		letter-spacing: 1px;
	}
}

/* @media only screen and (max-width: 775px) { */
@media only screen and (max-width: 800px) {
	.Footer footer {
		height: 147px;
		align-items: flex-start;
		padding: 20px 20px 0 20px;
	}

	.Footer .socialList {margin-right: 0;}
	.Footer .socialList .instagram {margin-left: 20x;}

	.Footer .appStoreBut {
		position: absolute;
		width: auto;
		bottom: 20px; left: 20px; right: 20px;
	}

	.Footer .terms .footerLinks {
		letter-spacing: 1px;
	}
}

@media only screen and (max-width: 430px) {
	.Footer .terms {align-items: flex-end;}
	.Footer .promo {align-items: flex-end;}

	.Footer footer {
		height: 174px;
	}

	.Footer .terms {
		align-items: flex-start;
		flex-direction: column;
		height: 63px;
	}

	.Footer .terms .footerLinks {
		font-weight: 600;
		margin-right: 0;
		letter-spacing: 2px;
	}

	.Footer .terms :first-child {
		position: relative;
	}

	.Footer .promo {
		height: 63px;
	}
}

.Newsletter {
	z-index: 110;
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	justify-content: center;
	align-items: center;
}

.Newsletter .alert {
	z-index: 110;
	max-width: 41.375rem;
	margin: 0 0.875rem;
	width: 100%;
	height: -webkit-max-content;
	height: -moz-max-content;
	height: max-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #EFEFEF;
	-webkit-transition: 3.1s;
	transition: 3.1s;
}

.Newsletter .title {
	font-weight: 800;
	font-size: 1.06rem;
	margin-top: 2.3125rem;
	letter-spacing: 0.5px;
}

.Newsletter .text {
	font-weight: 600;
	/* font-size: 0.8125rem; */
	font-size: 11px;
	margin: 2.625rem 1.5625rem 0 1.5625rem;
	letter-spacing: 1px;
	text-align: center;
}

.Newsletter .textField {
	font-weight: 500;
	font-size: 0.8125rem;
	letter-spacing: 1.5px;
	max-width: 26.44rem;
	/* width: 100%; */
	width: calc(100% - 1.5625rem - 1.5625rem);
	margin: 1.875rem 1.5625rem 0 1.5625rem;
	border: none;
	text-align: center;
	padding: 11px;
	font-family: 'Montserrat', sans-serif;
	outline: none;
	text-transform: uppercase;
}

.Newsletter .errMessage {
	font-weight: 700;
	font-size: 0.5625rem;
	margin: 0.25rem 1.5625rem 0 1.5625rem;
	letter-spacing: 3px;
	text-align: center;
	text-transform: uppercase;
	color: #D34B4B;
}

.Newsletter .nextStep {
	font-weight: 800;
	font-size: 0.5rem;
	margin: 0.8125rem 1.5625rem 0 1.5625rem;
	letter-spacing: 1.1px;
	text-align: center;
	text-transform: uppercase;
}

.Newsletter .subBut {
	font-weight: 800;
	font-size: 0.8125rem;
	margin: 3.1875rem 0 2.0625rem;
	background-color: rgba(0, 0, 0, 0);
}




.Newsletter .overlay {
	z-index: 109;
	position: absolute;
	left: 0; right: 0; top: 0; bottom: 0;
	background-color: rgba(0, 0, 0, 0.29);
}



@media only screen and (max-width: 420px) {
	.Newsletter .title {
		margin-top: 1.25rem;
	}
	.Newsletter .text {
		margin-top: 1.5625rem;
	}
	
	.Newsletter .textField {
		margin-top: 1.5625rem;
	}
	
	.Newsletter .errMessage {

	}
	
	.Newsletter .nextStep {

	}
	
	.Newsletter .subBut {
		margin-bottom: 1.25rem;
	}
}

.Submit {
	position: relative;
	min-height: 100vh;
}

.Submit .whole-container {
	display: flex;
	justify-content: center;
	padding: 127px calc((100vw - 912px - 211px) / 2)
}

.Submit .whole-container h1 {
	margin-bottom: 75px;
	font-size: 21px;
	font-weight: 800;
}

.Submit .wrapper .form {
	display: flex;
	width: 648px;
	flex-direction: column;
}

.Submit .form input {
	border-radius: 0;
	-webkit-appearance: none;
}

.Submit .wrapper .block-1 {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
}

.Submit .form .input-header {
	font-size: 9px;
	color: #2D2D2D;
	letter-spacing: 0.7px;
	text-align: left;
}

.Submit .input-header-mail {
	font-size: 9px;
	color: #2D2D2D;
	letter-spacing: 0.7px;
	text-align: left;
	margin-top: 8px;
}

.Submit .wrapper .textField {
	margin-top: 8px;
	border: none;
	text-align: left;
	font-family: 'Montserrat', sans-serif;
	outline: none;
	text-transform: uppercase;
	background-color: #F2F2F2;
	width: 315px;
	height: 45px;
	letter-spacing: 1px;
	font-size: 13px;
	color: #282828;
	padding: 0 14px;
}	








/* 2 */

.Submit .wrapper .block-2 {
	margin-top: 25px;
	/* overflow: auto; */
}

.Submit .wrapper .clothes {
	display: grid;
	grid-template-columns: 315px 315px;
	grid-template-rows: auto;
	grid-gap: 18px;
	margin-top: 8px;
}

.Submit .clothing-wrapper {
	width: 315px;
}


.Submit .wrapper .block-2 input {
	border: none;
	text-align: left;
	font-family: 'Montserrat', sans-serif;
	outline: none;
	background-color: #F2F2F2;
	height: 45px;
	letter-spacing: 1px;
	font-size: 12px;
	color: #282828;
	padding: 0 14px;
}

.Submit .select {
	border: 0px none;
	border-radius: 0;
	-webkit-appearance: none;
	text-align: left;
	font-family: 'Montserrat', sans-serif;
	outline: none;
	text-transform: uppercase;
	background-color: #F2F2F2;
	height: 45px;
	letter-spacing: 1px;
	font-size: 12px;
	color: #282828;
	padding-left: 14px;
	width: 153px;
}

.Submit .textField-brand {
	width: 153px;
	margin-left: 8px;
	text-transform: uppercase;
}

.Submit .textField-link {
	width: 100%;
	margin: 8px 0 0 0;
}

.Submit .addOutfit {
	width: 23px;
	height: 23px;
	background-color: #F2F2F2;
	position: relative;
}

.Submit .whole-container .icon-container {
	top: 0; left: 0; right: 0; bottom: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-transform-origin: center;
	        transform-origin: center;
}

.Submit .icon-container div { background-color: #282828; }

.Submit .icon-1 {
	width: 10px;
	height: 1px;
}

.Submit .icon-2 {
	width: 1px;
	height: 10px;
}


::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #BCBCBC;
	opacity: 1; /* Firefox */
}


:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #BCBCBC;
	opacity: 1; /* Firefox */
}


::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #BCBCBC;
	opacity: 1; /* Firefox */
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #BCBCBC;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #BCBCBC;
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: #BCBCBC;
}






/* 4 */
.Submit .drop-container {
	width: 315px;
	-webkit-tap-highlight-color: transparent;
	border: none;
	outline: none;
}

.Submit .block-4 .chooseFile  {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.Submit .block-4 .chooseFile + label {
	margin-top: 52px;
	width: 315px;
	height: 100px;
	background-color: #F2F2F2;
	font-size: 1.25em;
	font-weight: 700;
	display: inline-block;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 0.8px;
}

.Submit .fileName-container {
	margin-top: 6px;
	display: flex;
}

.Submit .fileName-container p {
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	padding: 8px 13px;
	background-color: #F2F2F2;
	font-size: 10px;
}

.Submit .fileName-container .removeButton {
	width: 29px;
	height: 29px;
	background-color: #F2F2F2;
	margin-left: 6px;
	position: relative;
}

.Submit .closeIcon {
	width: 11px;
	height: 11px;
	background-color: rgba(0, 0, 0, 0);
}
	



/* SUBMIT */
.Submit .submitBut {
	width: 141px;
	height: 37px;
	background-color: #282828;
	color: white;
	font-size: 13px;
	letter-spacing: 2px;
	font-weight: 600;
	margin-top: 80px;
}

.Submit .status {
	margin-top: 26px;
	font-size: 10px;
	color: #DB0404;
	font-weight: 700;
	letter-spacing: 0.8px;
	text-transform: uppercase;
}


/* THANKS */
.Submit .thanks {
	margin-top: 55px;
	font-weight: 700;
	font-size: 18px;
	color: #282828;
	letter-spacing: 0.94px;
}

.Submit .thanks-notice {
	margin-top: 3px;
	font-weight: 600;
	font-size: 11px;
	color: #282828;
	letter-spacing: 0.66px;
}



.Submit .emptyMessage {
	margin-top: 9px;
	font-weight: 600;
	font-size: 9px;
	color: #C10A0A;
	letter-spacing: 1.29px;
	text-align: left;
	text-transform: uppercase;
}




/* Phone */
@media only screen and (max-width: 750px) {	
	.Submit .whole-container {
		padding: 80px 0px 230px 0;
	}

	.Submit .whole-container .wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.Submit .whole-container h1 {
		margin: 0px 0px 42px 0;
		font-size: 20px;
		letter-spacing: 0.5px;
	}

	.Submit .wrapper .form {
		width: 100vw;
		flex-direction: column;
		align-items: center;
	}

	/* 1 */

	.Submit .wrapper .block-1 {
		padding: 20px;
		width: 100vw;
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: center;
	}

	.Submit .wrapper .textField {
		margin-top: 8px;
		max-width: 330px;
		width: calc(100vw - 40px);
		height: 45px;
	}	

	#input-header-last {
		margin-top: 13px;
	}

	.input-header-mail {
		margin-top: 13px;
	}



	

	/* 2 */

	.Submit .wrapper .clothes {
		display: grid;
		grid-template-columns: auto;
		grid-template-rows: auto;
		grid-gap: 18px;
		margin-top: 8px;
	}
	
	.Submit .clothing-wrapper {
		max-width: 330px;
		width: calc(100vw - 40px);
	}
	
	.Submit .select {
		width: calc(100% / 2 - 4px);
	}
	
	.Submit .textField-brand {
		width: calc(100% / 2 - 4px);
		margin-left: 8px;
		text-transform: uppercase;
	}
	
	.Submit .textField-link {
		width: 100%;
		margin: 8px 0 0 0;
	}

	
	.Submit .addOutfit {
		width: 40px;
		height: 40px;
	}

	.Submit .icon-1 {
		width: 15px;
		height: 2px;
	}
	
	.Submit .icon-2 {
		width: 2px;
		height: 15px;
	}


	/* 4 */

	.block-4 {
		max-width: 330px;
		width: calc(100vw - 40px);
	}

	.Submit .drop-container {
		max-width: 330px;
		width: calc(100vw - 40px);
	}

	.Submit .block-4 .chooseFile + label {
		margin-top: 50px;
		max-width: 330px;
		width: calc(100vw - 40px);
	}



	.Submit .fileName-container {
		width: calc(100vw - 40px);
		height: -webkit-max-content;
		height: -moz-max-content;
		height: max-content;
	}
	
	.Submit .fileName-container p {
		max-width: calc(330px - 29px - 6px);
	}
	
	.Submit .fileName-container .removeButton {
		width: 29px;
		height: 29px;
		background-color: #F2F2F2;
		margin-left: 6px;
		position: relative;
	}





	/* SUBMIT */
	.Submit .submitBut {
		max-width: 330px;
		width: calc(100vw - 40px);
		height: 47px;
		font-size: 14px;
		letter-spacing: 0.8px;
		font-weight: 700;
		margin-top: 50px;
	}

	.Submit .status {
		margin-left: 20px; margin-right: 20px;
		text-align: center;
	}


	/* THANKS */
	.Submit .thanks {
		margin-left: 20px; margin-right: 20px;
		text-align: center;
	}

	.Submit .thanks-notice {
		margin-left: 20px; margin-right: 20px;
		text-align: center;
	}
}

.Last-Step {
	position: relative;
	min-height: 100vh;
}


.Last-Step input[type=text] {
	border-radius: 0;
	-webkit-appearance: none;
}

.Last-Step .whole-container {
	display: flex;
	justify-content: center;
	padding: 127px 0;
}

.Last-Step .whole-container .wrapper {
	max-width: 1000px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.Last-Step .whole-container h1 {
	margin-bottom: 75px;
	font-size: 25px;
	font-weight: 800;
}

.Last-Step .whole-container h2 {
	margin-bottom: 5px;
	font-size: 16px;
	font-weight: 700;
}

.Last-Step .paypalme {
	font-size: 14px;
	font-weight: 500;
}

.Last-Step .paypalme a {
	color: #3FA1EC;
}





/* FORM */
  
  .Last-Step .wrapper .form {
	display: flex;
	max-width: 1000px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	flex-direction: column;
  }

  .Last-Step .textField-paypal {
	margin-top: 8px;
	border: none;
	text-align: left;
	font-family: 'Montserrat', sans-serif;
	outline: none;
	background-color: #F2F2F2;
	width: 315px;
	height: 45px;
	letter-spacing: 1px;
	font-size: 13px;
	color: #282828;
  	padding: 0 14px;
}	

.Last-Step .form h3 {
	font-size: 9px;
	color: #2D2D2D;
	letter-spacing: 0.7px;
	text-align: left;
	font-weight: 500;
}

.Last-Step .input-header-insta {
	margin-top: 32px;
}

.Last-Step .emptyMessage {
	margin-top: 9px;
	font-weight: 600;
	font-size: 9px;
	color: #C10A0A;
	letter-spacing: 1.29px;
	text-align: left;
}

/*  */

.Last-Step .form p {
	font-weight: 600;
	font-size: 10px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.Last-Step .checkbox-header-1 {
	margin-top: 77px;
}
.Last-Step .checkbox-header-2 {
	margin-top: 34px;
}

.Last-Step .checkbox-conatiner-1 {
	margin-top: 8px;
	display: grid;
	grid-template-columns: 15px auto;
	grid-template-rows: auto;
	/* display: flex;
	flex-direction: row;
	justify-content: center; */
}

.Last-Step .form label {
	grid-column: 2/3;
	margin-left: 8px;
	letter-spacing: 0.3px;
	font-size: 0.8rem;
}

.Last-Step .checkbox + label {
	cursor:pointer;
}

.Last-Step .checkbox {
	grid-column: 1/2;
	min-width: 15px;
	min-height: 15px;
	width: 15px;
	height: 15px;
}

.Last-Step .checkbox-conatiner-2 {
	margin-top: 7px;
}





.Last-Step .sendBut {
	width: 141px;
	height: 37px;
	background-color: #282828;
	color: white;
	font-size: 13px;
	letter-spacing: 2px;
	font-weight: 600;
	margin-top: 80px;
  }



  .Last-Step .contact {
	margin-top: 62px;
	font-weight: 600;
	font-size: 10px;
	color: #282828;
	letter-spacing: 0.1px;
	text-align: left;
  }

  .Last-Step .contact a {
	color: #3FA1EC;
  }

  .Last-Step .status {
	margin-top: 26px;
	font-size: 10px;
	color: #DB0404;
	font-weight: 700;
	letter-spacing: 0.8px;
	text-transform: uppercase;
}




@media only screen and (max-width: 1700px) {	
	.Last-Step .whole-container .wrapper {
		padding-left: 200px;
		width: 900px;
	}

	.Last-Step .wrapper .form {
		width: 900px;
	  }
}

@media only screen and (max-width: 1200px) {	
	.Last-Step .whole-container .wrapper {
		padding-left: 0px;
		width: calc(100vw - 80px);
	}

	.Last-Step .wrapper .form {
		width: calc(100vw - 80px);
	}

	.Last-Step .whole-container {
		padding: 127px 0;
	}
}

  /* Phone */
@media only screen and (max-width: 750px) {	
	.Last-Step .whole-container {
		display: flex;
		justify-content: center;
		padding: 90px 0 250px 0;
	}

	.Last-Step .whole-container .wrapper {
		width: calc(100vw - 40px);
	}
	.Last-Step .wrapper .form {
		width: calc(100vw - 40px);
	}

	.Last-Step .status {
		margin-left: 20px; margin-right: 20px;
		text-align: center;
	}

	/* SUBMIT */
	.Last-Step .sendBut {
		max-width: 480px;
		width: calc(100vw - 40px);
		height: 47px;
		font-size: 14px;
		letter-spacing: 0.8px;
		font-weight: 700;
		margin-top: 50px;
	}
}
.Page404 {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Page404 .wrapper {
	display: inline;
}

.Page404 h1 {
	font-size: 28px;
	letter-spacing: 5px;
	font-weight: 700;
}

.Page404 h2 {
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 2px;
	margin: 8px 0 0 0;
}

.Page404 .link p {
	padding: 10px 0 0 29px;
	background-color: #303030;
	color: white;
	width: 164px;
	height: 36px;
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 6px;
	text-align: left;
	margin-top: 54px;
}


@media only screen and (max-width: 1100px) {
	.Page404 .wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.Page404 .link p {
		padding-left: 0;
		text-align: center;
		margin-top: 0;
	}

	.Page404 .link {
		margin-top: 54px;
	}

	.Page404 h2 {
		font-size: 10px;
		font-weight: 600;
		letter-spacing: 1.5px;
		text-align: center;
		margin: 8px 15px 0 15px;
	}

	.WholePage404 {
		position: relative;
		padding-bottom: 170px;
	}
}

.ThankYou {
	position: relative;
	min-height: 100vh;
}

.ThankYou .wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
}

.ThankYou .content h1 {
	font-weight: 700;
	letter-spacing: 1.33px;
	text-align: center;
}
.ThankYou .submit-h1 {
	font-size: 28px;
}

.ThankYou .last-h1 {
	font-size: 38px;
}

.ThankYou .content h2 {
	font-weight: 700;
	letter-spacing: 1.33px;
	font-size: 24px;
	text-align: center;
}

.ThankYou .h p {
	font-weight: 600;
	font-size: 13px;
	letter-spacing: 0.78px;
	text-align: center;
	margin-top: 12px;
}

.ThankYou .content .more {
	background: #282828;
	font-weight: 700;
	font-size: 11px;
	color: #FFFFFF;
	letter-spacing: 0.78px;
	text-align: center;
	width: 217px;
	height: 42px;
	margin-top: 51px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ThankYou .h {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 0 20px;
}


.ThankYou .content .contact {
	position: absolute;
	bottom: 13.39vh;
	font-weight: 600;
	font-size: 11px;
	letter-spacing: 0.78px;
	text-align: center;
}

.ThankYou .content .contact a {
	color: #3FA1EC;
}




@media only screen and (max-width: 1200px) {
	.ThankYou .content .contact {
		bottom: 20.5vh;
	}
	
	.ThankYou .content .contact a {
		color: #3FA1EC;
	}
}


@media only screen and (max-width: 750px) {

	.ThankYou .wrapper {
		display: inline;
	}

	.ThankYou .content .contact {
		position: static;
		margin-top: 110px;
		padding-bottom: 220px;
	}

	.ThankYou .submit-h1 {
		margin-top: 117px;
	}

	.ThankYou .last-h1 {
		font-size: 30px;
		line-height: 35px;
		margin-top: 117px;
	}
	
	.ThankYou .h h2 {
		font-size: 19px;
		line-height: 25px;
		margin-top: 3px;
	}

	.ThankYou .h p {
		font-size: 12px;
	}
}

a {
  text-decoration: none;
  color: #282828;
	background-color: rgba(0, 0, 0, 0)
}

button:active {
  color: #282828;
}


button {
  padding: 0;
	cursor: pointer;
	text-decoration: none;
	border: none;
	outline: none;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Montserrat', sans-serif;
}

