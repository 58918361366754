.Footer {
	bottom: 0;
	/* margin-bottom: -97px; */
}

@supports (padding-bottom: constant(safe-area-inset-bottom)) {
	.Footer {
		--safe-area-inset-bottom: constant(safe-area-inset-bottom);
		bottom: calc(var(--safe-area-inset-bottom));
	}
}
@supports (padding-bottom: env(safe-area-inset-bottom)) {
	.Footer {
		--safe-area-inset-bottom: env(safe-area-inset-bottom);
		bottom: calc(var(--safe-area-inset-bottom));
	}
} 

.Footer footer {
	/* background-color: rebeccapurple; */
	width: 100vw;
	height: 97px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 07.083333vw;
}

.Footer p {background-color: rgba(0, 0, 0, 0);}


/* Terms */
.Footer .terms {
	position: relative;
	height: 41px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* flex-wrap: wrap; */
	background-color: white;
}

.Footer .terms .footerLinks {
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 3px;
	margin-right: 30px;
}

.Footer .terms :last-child {margin-right: 0px;}

/* Promo */
.Footer .promo {
	display: flex;
	align-items: center;
	background-color: white;
	height: 41px;
}

.Footer .socialList {
	margin-right: 39px;
	background-color: white;
}

.Footer .socialList .icon {
	width: 17px;
	height: 17px;
	background-color: white;
}

.Footer .socialList .instagram {
	margin-left: 15px;
}

.Footer .appStoreBut {
	width: 275px;
	height: 41px;
	background-color: #2C2C2C;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Footer .appStoreBut p {
	color: white;
	font-size: 11px;
	font-weight: 500;
	letter-spacing: 3.9px;
}

.Footer .appStoreBut .apple {
	margin-right: 11px;
	width: 11px;
	height: 13px;
	background-color: rgba(0, 0, 0, 0)
}




/* Tablet */
@media only screen and (max-width: 1290px) {

}

@media only screen and (max-width: 1200px) {
	.Footer footer {padding: 0px 40px;}
	.Footer .terms .footerLinks {margin-right: 29px;}
	.Footer .terms :last-child {margin-right: 0px;}

	.Footer .socialList {margin-right: 27px}
	.Footer .socialList .instagram {margin-left: 13px;}
	.Footer .terms .footerLinks {font-size: 9px;}
}

@media only screen and (min-height: 1200px) and (max-width: 1290px) {

}


/* Mobile */
@media only screen and (max-width: 1000px) {
	.Footer .terms {align-items: flex-end;}
	.Footer .promo {align-items: flex-end;}

	.Footer .terms :first-child {
		position: absolute;
		top: 0;
	}

	.Footer .terms .footerLinks {
		letter-spacing: 1px;
	}
}

/* @media only screen and (max-width: 775px) { */
@media only screen and (max-width: 800px) {
	.Footer footer {
		height: 147px;
		align-items: flex-start;
		padding: 20px 20px 0 20px;
	}

	.Footer .socialList {margin-right: 0;}
	.Footer .socialList .instagram {margin-left: 20x;}

	.Footer .appStoreBut {
		position: absolute;
		width: auto;
		bottom: 20px; left: 20px; right: 20px;
	}

	.Footer .terms .footerLinks {
		letter-spacing: 1px;
	}
}

@media only screen and (max-width: 430px) {
	.Footer .terms {align-items: flex-end;}
	.Footer .promo {align-items: flex-end;}

	.Footer footer {
		height: 174px;
	}

	.Footer .terms {
		align-items: flex-start;
		flex-direction: column;
		height: 63px;
	}

	.Footer .terms .footerLinks {
		font-weight: 600;
		margin-right: 0;
		letter-spacing: 2px;
	}

	.Footer .terms :first-child {
		position: relative;
	}

	.Footer .promo {
		height: 63px;
	}
}
