
.Submit {
	position: relative;
	min-height: 100vh;
}

.Submit .whole-container {
	display: flex;
	justify-content: center;
	padding: 127px calc((100vw - 912px - 211px) / 2)
}

.Submit .whole-container h1 {
	margin-bottom: 75px;
	font-size: 21px;
	font-weight: 800;
}

.Submit .wrapper .form {
	display: flex;
	width: 648px;
	flex-direction: column;
}

.Submit .form input {
	border-radius: 0;
	-webkit-appearance: none;
}

.Submit .wrapper .block-1 {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
}

.Submit .form .input-header {
	font-size: 9px;
	color: #2D2D2D;
	letter-spacing: 0.7px;
	text-align: left;
}

.Submit .input-header-mail {
	font-size: 9px;
	color: #2D2D2D;
	letter-spacing: 0.7px;
	text-align: left;
	margin-top: 8px;
}

.Submit .wrapper .textField {
	margin-top: 8px;
	border: none;
	text-align: left;
	font-family: 'Montserrat', sans-serif;
	outline: none;
	text-transform: uppercase;
	background-color: #F2F2F2;
	width: 315px;
	height: 45px;
	letter-spacing: 1px;
	font-size: 13px;
	color: #282828;
	padding: 0 14px;
}	








/* 2 */

.Submit .wrapper .block-2 {
	margin-top: 25px;
	/* overflow: auto; */
}

.Submit .wrapper .clothes {
	display: grid;
	grid-template-columns: 315px 315px;
	grid-template-rows: auto;
	grid-gap: 18px;
	margin-top: 8px;
}

.Submit .clothing-wrapper {
	width: 315px;
}


.Submit .wrapper .block-2 input {
	border: none;
	text-align: left;
	font-family: 'Montserrat', sans-serif;
	outline: none;
	background-color: #F2F2F2;
	height: 45px;
	letter-spacing: 1px;
	font-size: 12px;
	color: #282828;
	padding: 0 14px;
}

.Submit .select {
	border: 0px none;
	border-radius: 0;
	-webkit-appearance: none;
	text-align: left;
	font-family: 'Montserrat', sans-serif;
	outline: none;
	text-transform: uppercase;
	background-color: #F2F2F2;
	height: 45px;
	letter-spacing: 1px;
	font-size: 12px;
	color: #282828;
	padding-left: 14px;
	width: 153px;
}

.Submit .textField-brand {
	width: 153px;
	margin-left: 8px;
	text-transform: uppercase;
}

.Submit .textField-link {
	width: 100%;
	margin: 8px 0 0 0;
}

.Submit .addOutfit {
	width: 23px;
	height: 23px;
	background-color: #F2F2F2;
	position: relative;
}

.Submit .whole-container .icon-container {
	top: 0; left: 0; right: 0; bottom: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	justify-content: center;
	align-items: center;
	transform-origin: center;
}

.Submit .icon-container div { background-color: #282828; }

.Submit .icon-1 {
	width: 10px;
	height: 1px;
}

.Submit .icon-2 {
	width: 1px;
	height: 10px;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #BCBCBC;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #BCBCBC;
}

::-ms-input-placeholder { /* Microsoft Edge */
	color: #BCBCBC;
}






/* 4 */
.Submit .drop-container {
	width: 315px;
	-webkit-tap-highlight-color: transparent;
	border: none;
	outline: none;
}

.Submit .block-4 .chooseFile  {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.Submit .block-4 .chooseFile + label {
	margin-top: 52px;
	width: 315px;
	height: 100px;
	background-color: #F2F2F2;
	font-size: 1.25em;
	font-weight: 700;
	display: inline-block;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 0.8px;
}

.Submit .fileName-container {
	margin-top: 6px;
	display: flex;
}

.Submit .fileName-container p {
	width: max-content;
	padding: 8px 13px;
	background-color: #F2F2F2;
	font-size: 10px;
}

.Submit .fileName-container .removeButton {
	width: 29px;
	height: 29px;
	background-color: #F2F2F2;
	margin-left: 6px;
	position: relative;
}

.Submit .closeIcon {
	width: 11px;
	height: 11px;
	background-color: rgba(0, 0, 0, 0);
}
	



/* SUBMIT */
.Submit .submitBut {
	width: 141px;
	height: 37px;
	background-color: #282828;
	color: white;
	font-size: 13px;
	letter-spacing: 2px;
	font-weight: 600;
	margin-top: 80px;
}

.Submit .status {
	margin-top: 26px;
	font-size: 10px;
	color: #DB0404;
	font-weight: 700;
	letter-spacing: 0.8px;
	text-transform: uppercase;
}


/* THANKS */
.Submit .thanks {
	margin-top: 55px;
	font-weight: 700;
	font-size: 18px;
	color: #282828;
	letter-spacing: 0.94px;
}

.Submit .thanks-notice {
	margin-top: 3px;
	font-weight: 600;
	font-size: 11px;
	color: #282828;
	letter-spacing: 0.66px;
}



.Submit .emptyMessage {
	margin-top: 9px;
	font-weight: 600;
	font-size: 9px;
	color: #C10A0A;
	letter-spacing: 1.29px;
	text-align: left;
	text-transform: uppercase;
}




/* Phone */
@media only screen and (max-width: 750px) {	
	.Submit .whole-container {
		padding: 80px 0px 230px 0;
	}

	.Submit .whole-container .wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.Submit .whole-container h1 {
		margin: 0px 0px 42px 0;
		font-size: 20px;
		letter-spacing: 0.5px;
	}

	.Submit .wrapper .form {
		width: 100vw;
		flex-direction: column;
		align-items: center;
	}

	/* 1 */

	.Submit .wrapper .block-1 {
		padding: 20px;
		width: 100vw;
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		align-items: center;
	}

	.Submit .wrapper .textField {
		margin-top: 8px;
		max-width: 330px;
		width: calc(100vw - 40px);
		height: 45px;
	}	

	#input-header-last {
		margin-top: 13px;
	}

	.input-header-mail {
		margin-top: 13px;
	}



	

	/* 2 */

	.Submit .wrapper .clothes {
		display: grid;
		grid-template-columns: auto;
		grid-template-rows: auto;
		grid-gap: 18px;
		margin-top: 8px;
	}
	
	.Submit .clothing-wrapper {
		max-width: 330px;
		width: calc(100vw - 40px);
	}
	
	.Submit .select {
		width: calc(100% / 2 - 4px);
	}
	
	.Submit .textField-brand {
		width: calc(100% / 2 - 4px);
		margin-left: 8px;
		text-transform: uppercase;
	}
	
	.Submit .textField-link {
		width: 100%;
		margin: 8px 0 0 0;
	}

	
	.Submit .addOutfit {
		width: 40px;
		height: 40px;
	}

	.Submit .icon-1 {
		width: 15px;
		height: 2px;
	}
	
	.Submit .icon-2 {
		width: 2px;
		height: 15px;
	}


	/* 4 */

	.block-4 {
		max-width: 330px;
		width: calc(100vw - 40px);
	}

	.Submit .drop-container {
		max-width: 330px;
		width: calc(100vw - 40px);
	}

	.Submit .block-4 .chooseFile + label {
		margin-top: 50px;
		max-width: 330px;
		width: calc(100vw - 40px);
	}



	.Submit .fileName-container {
		width: calc(100vw - 40px);
		height: max-content;
	}
	
	.Submit .fileName-container p {
		max-width: calc(330px - 29px - 6px);
	}
	
	.Submit .fileName-container .removeButton {
		width: 29px;
		height: 29px;
		background-color: #F2F2F2;
		margin-left: 6px;
		position: relative;
	}





	/* SUBMIT */
	.Submit .submitBut {
		max-width: 330px;
		width: calc(100vw - 40px);
		height: 47px;
		font-size: 14px;
		letter-spacing: 0.8px;
		font-weight: 700;
		margin-top: 50px;
	}

	.Submit .status {
		margin-left: 20px; margin-right: 20px;
		text-align: center;
	}


	/* THANKS */
	.Submit .thanks {
		margin-left: 20px; margin-right: 20px;
		text-align: center;
	}

	.Submit .thanks-notice {
		margin-left: 20px; margin-right: 20px;
		text-align: center;
	}
}
